import React from "react";
import TankYouPage from "../components/TankYouPage";
import Layout from "../components/Layout";

const seo = {
  title: "Thank You ",
  description:
  "Thank You Page",
};

const FormSubmittedThankYou = () => {
  return (
    <Layout seo={seo} backgroundColorUnderneath="white">
      <TankYouPage />
    </Layout>
  );
};

export default FormSubmittedThankYou;
